<style lang="less" scoped>
  .project-single-page {
    padding: 5px;
    display: flex;
    flex-direction: column;
  }
  .title {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .toolbox {
    margin: 5px;
    padding: 10px;
    background-color: #FFF;
    border-radius: 5px;
  }
  .query-form {
    margin-top: 10px;
  }
  .item-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    .wrap {
      width: 25%;
      padding: 5px;
    }
    &.col2 {
      .wrap { width: 50% }
    }
    .item {
      padding: 10px;
      background-color: #FFF;
      border-radius: 5px;
      border: 1px solid #EEE;
    }
  }
  .type-label {
    font-weight: bold;
    color: #515a6e;
  }
  .form {
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
    background-color: #FFF;
  }
  .project-label {
    cursor: pointer;
    font-size: 12px;
    color: #808695;
  }
</style>

<style>
  .project-single-page .ivu-tag { border: none; }
</style>

<template>
  <div class="project-single-page">
    <div class="toolbox">
      <div class="title">
        <div style="display: flex;align-items: center;">
          <fm-title v-if="!pfmProjectId" title-text="单项奖励" />
          <i-input placeholder="请输入搜索内容" clearable v-model="searchKey">
            <span slot="prepend">搜索</span>
          </i-input>
        </div>
        <div>
          <Button v-if="isRouterBack" style="margin-right: 10px;" icon="ios-arrow-back" @click="routerBack">返回</Button>
          <Button :loading="loading.load" style="margin-right: 10px;" icon="md-refresh-circle" @click="loadData">刷新</Button>
          <Button v-if="$authFunsProxyNoRoute['performance.project-singleManage']" type="primary" @click="addClick" icon="md-add-circle">新增</Button>
        </div>
      </div>
    </div>
    <div style="flex: 1;height: 0;overflow: auto;" v-loadingx="loading.load">
      <div class="form" v-if="status.form">
        <fm-form label-alone label-align="left">
          <fm-form-item label="项目" v-if="!pfmProjectId">
            <div style="font-weight: bold;" v-if="formData.pfmProjectId">
              已选: <span>{{formData.pfmProjectName}}</span> - <span>{{formData.pfmProjectHisCode}}</span>
            </div>
            <Select
              @on-change="onProjectChange"
              clearable
              filterable
              placeholder="输入名称或编码搜索"
              :remote-method="projectSearch"
              :loading="loading.project">
              <Option v-for="item in projectList" :value="item.id" :key="item.id">{{item.label}}</Option>
            </Select>
          </fm-form-item>
          <fm-form-item label="类型">
            <Select
              v-model="formData.type"
              clearable
              filterable
              placeholder="请选择类型">
              <Option v-for="item in pfmSingleType" :value="item.value" :key="item.value">{{item.label}}</Option>
            </Select>
          </fm-form-item>
          <fm-form-item label="奖励值">
            <fm-input-new v-model="formData.pfmValue" placeholder="请输入奖励值"/>
          </fm-form-item>
          <fm-form-item label="奖励方式">
            <Select
              v-model="formData.pfmType"
              clearable
              placeholder="请选择奖励方式">
              <Option v-for="item in pfmAwardType" :value="item.value" :key="item.value">{{item.label}}</Option>
            </Select>
          </fm-form-item>
        </fm-form>
        <div style="margin-top: 10px;display: flex;">
          <Button :loading="loading.submit" style="flex: 1;" @click="status.form = false" type="default" long>取消</Button>
          <Button :loading="loading.submit" style="flex: 1;margin-left: 10px;" @click="submit" type="info" long>确定</Button>
        </div>
      </div>
      <template v-else>
        <div class="item-list" :class="{col2: pfmProjectId !== null}">
          <div class="wrap" v-for="item in showDataList" :key="item.id">
            <div class="item">
              <Row justify="space-between" align="middle">
                <i-col>
                  <span class="type-label">{{item.typeText}}</span>
                </i-col>
                <i-col>{{item.pfmTypeText}}</i-col>
                <i-col>
                  <Tag color="blue">{{item.pfmValue}}</Tag>
                </i-col>
              </Row>
              <Row style="margin-top: 10px;" justify="space-between" :gutter="10" v-if="$authFunsProxyNoRoute['performance.project-singleManage']">
                <i-col>
                  <Tooltip :max-width="250" transfer>
                    <span class="project-label">关联项目</span>
                    <div slot="content">
                      <p>名称: {{item.pfmProjectName}}</p>
                      <p>编码: {{item.pfmProjectHisCode}}</p>
                    </div>
                  </Tooltip>
                </i-col>
                <i-col>
                  <Button size="small" icon="ios-brush" type="text" @click="itemEdit(item)">编辑</Button>
                  <Poptip transfer confirm @on-ok="itemDel(item)" title="确定删除吗？">
                    <Button icon="md-trash" size="small" type="text">删除</Button>
                  </Poptip>
                </i-col>
              </Row>
            </div>
          </div>
        </div>
        <TableEmpty style="display: flex;align-items: center;flex-direction: column;" v-if="!dataList.length" />
      </template>
    </div>
  </div>
</template>

<script>
import { request } from '@/api'
import TableEmpty from '@/components/base/TableEmpty.vue'

function getDefaultFormData () {
  return {
    id: null,
    pfmProjectId: null,
    pfmProjectName: null,
    pfmProjectHisCode: null,
    pfmType: null,
    type: null,
    pfmValue: null
  }
}

export default {
  components: { TableEmpty },
  props: {
    pfmProjectId: { type: Number, default: null }
  },
  data () {
    return {
      dataList: [],
      projectList: [],
      formData: getDefaultFormData(),
      status: {
        form: false
      },
      searchKey: null,
      loading: {
        submit: false,
        load: false,
        project: false
      }
    }
  },
  computed: {
    pfmSingleType () {
      return this.$store.getters.pfmSingleType
    },
    pfmAwardType () {
      return this.$store.getters.pfmAwardType
    },
    isRouterBack () {
      return Boolean(this.$route.query.back)
    },
    showDataList () {
      const searchKey = this.searchKey
      return searchKey ? this.dataList.filter(v => (v.typeText || '').indexOf(searchKey) > -1) : this.dataList
    }
  },
  methods: {
    async onProjectChange (id) {
      this.formData.pfmProjectId = id
      const res = await request('/performance/pfm_project', 'get', { id })
      if (res.length) {
        this.formData.pfmProjectName = res[0].name
        this.formData.pfmProjectHisCode = res[0].hisCode
      } else {
        this.formData.pfmProjectName = '其他'
        this.formData.pfmProjectHisCode = '无'
      }
    },
    async projectSearch (key) {
      if (!key) return
      this.loading.project = true
      try {
        const projectList = await request('/performance/pfm_project', 'get', { key })
        projectList.forEach(v => {
          const name = (v.name || '').length > 20 ? (v.name.substr(0, 20) + '...') : v.name
          v.label = name + ' - ' + v.hisCode
        })
        this.projectList = projectList
      } catch (e) {
        console.error(e)
      }
      this.loading.project = false
    },
    routerBack () {
      this.$router.back()
    },
    async loadData () {
      if (!this.$authFunsProxyNoRoute['performance.project-singleGet']) {
        return
      }
      this.loading.load = true
      const dataList = await request('/performance/pfm_project/single', 'get', this.pfmProjectId ? { pfmProjectId: this.pfmProjectId } : {})
      dataList.forEach(item => {
        const type = this.pfmSingleType.find(v => v.value === item.type)
        item.typeText = type ? type.label : item.type
        const awardType = this.pfmAwardType.find(v => v.value === item.pfmType)
        item.pfmTypeText = awardType ? awardType.label : item.pfmType
      })
      this.dataList = dataList
      this.loading.load = false
    },
    addClick () {
      this.formData = getDefaultFormData()
      this.status.form = true
    },
    itemEdit (item) {
      if (this.$authFunsProxyNoRoute['performance.project-singleManage']) {
        this.formData = JSON.parse(JSON.stringify(item))
        this.status.form = true
      }
    },
    async itemDel (item) {
      await request('/performance/pfm_project/single/' + item.id, 'delete')
      this.$Message.success('已删除')
      this.loadData()
    },
    async submit () {
      try {
        this.loading.submit = true
        if (this.pfmProjectId) {
          this.formData.pfmProjectId = this.pfmProjectId
        }
        if (this.formData.id) {
          await request('/performance/pfm_project/single/' + this.formData.id, 'put', this.formData)
        } else {
          await request('/performance/pfm_project/single', 'post', this.formData)
        }
        this.status.form = false
        this.$emit('refresh')
        this.$Message.success('操作成功')
        this.loadData()
        this.loading.submit = false
      } catch (e) {
        this.loading.submit = false
      }
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>